<template>
  <div>
    <Header> 提现 </Header>

    <div class="top_up">提现到</div>
    <div class="top_icon">
      <div class="way">
        <div><img src="../../../../static/image/icon.png" alt="" /></div>
        <div class="way_text">
          支付宝
          <span style="color: #999999">{{ anyAlipayaccount.account }}</span>
        </div>
      </div>
      <div v-if="anyAlipayaccount.account">
        <van-checkbox class="aaa" v-model="checked" @click="noe"></van-checkbox>
      </div>
      <div v-else @click="bindin">
        <div class="binding">去绑定</div>
      </div>
    </div>
    <!-- <div class="top_tow">
      <input
        v-model="username"
        type="text "
        class="top_text"
        placeholder="请输入支付宝账号姓名（与实名认证姓名一致）"
      />
    </div> -->
    <!-- <div class="top_icon">
      <div class="way">
        <div><img src="../../../../static/image/icon@2x.png" alt="" /></div>
        <div class="way_text">银行卡</div>
      </div>
      <div>
        <input type="radio" />
      </div>
    </div> -->
    <div class="showdow"></div>
    <div class="mon_text">提现金额</div>
    <div class="mon">
      <div class="symbol">￥</div>
      <input
        class="num"
        v-model="mony"
        type="number"
        placeholder="请输入提现金额"
      />
    </div>
    <div class="money_mon">
      钱包余额￥<i v-text="money"></i> ，<span @click="all">全部提现</span>
    </div>

    <div class="prompt">
      <div class="prompt_top">提示:</div>
      <div class="prompt_text" v-html="rule"></div>
      <!-- <div class="prompt_text">1、最低提现金额为100元，手续费为0.8%；</div>
      <div class="prompt_text">2、若手续费不足一元的，则按照一元收取；</div>
      <div class="prompt_text">3、只可在工作日9:00至17:00内提现，T+1到账；</div>
      <div class="prompt_text">4、具体以实际到账时间为准。</div> -->
      <!-- <div class="prompt_text">5、具体以实际到账时间为准。</div> -->
    </div>

    <div class="but" @click="payment">提现</div>
    <van-overlay :show="show">
      <div class="wap">
        <div class="wap_affirm">
          <div class="cha" @click="cha">×</div>
          <div>请输入交易密码</div>
          <div class="forgotPwd" @click="$router.push('/forgotPwd')">
            忘记密码
          </div>
        </div>
        <!-- 密码输入框 -->
        <van-password-input
          :value="value"
          :error-info="errorInfo"
          :gutter="10"
          :focused="showKeyboard"
          @focus="showKeyboard = true"
          @input="specifiName($event)"
        />
        <!-- 数字键盘 -->
        <van-number-keyboard
          :show="showKeyboard"
          @input="onInput"
          @delete="onDelete"
          @blur="showKeyboard = true"
        />
      </div>
    </van-overlay>
  </div>
</template>
<script>
export default {
  data() {
    return {
      mony: '',
      checked: '',
      pay_channel: '', // 选择充值方式
      money: '',
      value: '', //密码输入框
      errorInfo: '',
      showKeyboard: true,
      show: false,
      username: '', // 姓名
      anyAlipayaccount: {}, //我的支付宝
      isPayPassword: false,
      rule: '',
    };
  },
  mounted() {
    let params = {
      token: localStorage.getItem('token'),
    };

    this.$api.anyUserinfo(params).then((res) => {
      if (res.code == 0) {
        this.money = res.data.money;
        this.isPayPassword = res.data.is_pay_password;
      }
    });

    this.$api.anyAlipayaccount(params).then((res) => {
      if (res.code == 0) {
        this.anyAlipayaccount = res.data || {};
      }
    });

    this.$api.anyRuleInfo({ type: 'recharge_rule' }).then((e) => {
      if (e.code === 0) {
        this.rule = e.data.rule;
      }
    });
  },
  methods: {
    // 绑定
    bindin() {
      this.$router.push('/Alipay');
    },
    all() {
      this.mony = this.money;
    },

    // 选择支付宝
    noe() {
      let checked = document.querySelector('.but');
      if (this.checked == true) {
        checked.style.backgroundColor = '#0754d3';
        checked.style.color = ' #ffffff';
      } else {
        checked.style.backgroundColor = '#EEEEEE';
        checked.style.color = '#666666';
      }
    },

    onInput(key) {
      if (this.value.length > 5) return;
      this.value = (this.value + key).slice(0, 6);
      if (this.value.length === 6) {
        let params = {
          token: localStorage.getItem('token'),
          withdrawType: this.pay_channel,
          fee: this.mony,
          payPassword: this.value,
          name: this.username,
        };

        this.$api.WithdrawalBalance(params).then((res) => {
          if (res.code == 100000) {
            this.$toast('提现成功');
            this.$router.back();
          } else {
            this.$toast(res.msg);
          }
        });
      } else {
        this.errorInfo = '';
      }
    },
    onDelete() {
      this.value = this.value.slice(0, this.value.length - 1);
    },

    // 确认充值
    payment() {
      this.value = '';
      if (this.mony == '') {
        this.$toast.fail('请输入提现金额');
        return;
      }

      if (this.mony == '0') {
        this.$toast.fail('提现金额不能为零');
        return;
      }

      //最大提现两万，不包括两万
      if (Number(this.mony) > Number(this.money)) {
        this.$toast.fail('提现金额不能大于余额');
        return;
      }

      // 最少提现100不包括100元
      if (this.mony < 100) {
        this.$toast.fail('提现金额不能少于100元');
        return;
      }
      //最大提现两万，不包括两万
      if (this.mony > 20000) {
        this.$toast.fail('单笔提现金额不能超过两万元');
        return;
      }

      // 名字
      // if (this.username == '') {
      //   this.$toast.fail('请输入姓名');
      //   return;
      // }

      if (!this.isPayPassword) return this.$toast('请前往安全中心设置交易密码');

      if (this.checked == true) {
        this.pay_channel = 1;
        this.show = true;
      } else {
        this.$toast.fail('请选择提现方式');
      }
      // 支付宝充值
    },

    cha() {
      this.show = false;
    },
  },
};
</script>
<style lang="less" scoped>
input.num::-webkit-input-placeholder {
  font-size: 30px;
}

input.num:-moz-placeholder {
  font-size: 30px;
}

input.num::-moz-placeholder {
  font-size: 30px;
}
input.num:-ms-input-placeholder {
  font-size: 30px;
}

.prompt {
  font-size: 14px;
  padding-left: 17px;
  .prompt_top {
    font-weight: 600;
    font-size: 16px;
    margin-top: 10px;
  }
  .prompt_text {
    color: #999999;
    line-height: 30px;
  }
}
/deep/.van-password-input__item {
  //   background-color: #999999;
  border-radius: 5px;
  border: 2px #999999 solid;
  box-sizing: border-box;
}
/deep/.van-password-input {
  margin-bottom: 20px;
}
// .wap_affirm {
//   font-size: 17px;
//   // margin-top: 240px;
//   display: flex;
//   font-size: 20px;
//   margin-bottom: 20px;
//   width: 100%;
//   line-height: 60px;
//   .cha {
//     font-size: 40px;
//     color: #999999;
//     margin-right: 85px;
//     margin-left: 10px;
//   }
// }
.wap_affirm {
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  div {
    flex: 2;
    text-align: center;
    font-size: 16px;
  }
  .cha {
    flex: 1;
    color: #666666;
    text-align: left;
    font-size: 30px;
  }
  .forgotPwd {
    flex: 1;
    text-align: right;
    font-size: 14px;
    color: rgb(rgb(7 84 211));
  }
}
.wap {
  background-color: #ffffff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding-top: 10px;
}
/deep/.van-number-keyboard {
  position: relative;
}
.wap_form {
  font-size: 10px;
  margin-top: 210px;
}
.wap_balance {
  font-size: 10px;
  margin-bottom: 30px;
  span {
    color: #999999;
  }
}
.money_mon {
  color: #666666;
  font-size: 13px;
  margin-left: 17px;
  span {
    color: #0754d3;
  }
}
.but {
  width: 330px;
  height: 42px;
  background-color: #eeeeee;
  text-align: center;
  line-height: 42px;
  color: #666666;
  margin: 0 auto;
  font-size: 15px;
  border-radius: 5px;
  margin-top: 50px;
}
.mon_text {
  font-size: 14px;
  line-height: 50px;
  font-weight: bold;
  margin-left: 21px;
}
.mon {
  margin: 20px;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  .symbol {
    font-size: 32px;
  }
}
.num {
  width: 300px;
  border: 0;
  font-size: 35px;
}
.showdow {
  background-color: #f8f8f8;
  height: 5px;
}
.top_icon {
  display: flex;
  margin-left: 21px;
  align-items: center;
  justify-content: space-between;
  margin-right: 28px;
  height: 64px;
  border-bottom: 1px #f3f4f5 solid;
  .way {
    display: flex;
    align-items: center;

    img {
      width: 22px;
      height: 22px;
    }
    .way_text {
      font-size: 14px;
      padding-left: 10px;
    }
  }
}
.top_up {
  font-size: 14px;
  font-weight: bold;
  padding-left: 21px;
}
.topHeader {
  margin-bottom: 20px;
}
.top_tow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  height: 64px;
  .top_text {
    width: 100%;
    border: 0;
    font-size: 10px;
  }
}

.binding {
  font-size: 14px;
  color: #0754d3;
}
</style>
